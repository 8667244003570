<template>
  <section id="urgencylevels">
    <!-- Urgency levels Table Card -->
    <b-card no-body>
      <!-- Table Top -->
      <b-row>
        <b-col cols="12" md="12">
          <div class="d-flex align-items-center justify-content-end mt-2 mr-2">
            <b-button variant="primary" @click="active = !active;  data.selectedUrgencyLevel={score:50}">
              <feather-icon icon="PlusIcon" class="mr-25" />
              <span class="text-nowrap">Add level</span>
            </b-button>
          </div>
        </b-col>
      </b-row>
      <urgency-levels-table 
        @reload="loadUrgencyLevels"
        @editUrgencyLevel="editUrgencyLevel"
        @deleteUrgencyLevel="deleteUrgencyLevel"
        :table-data="data.urgencyLevels"
        class="mt-2 mt-md-2"
      />
    </b-card>
    <!--/ Urgency levels Table Card -->

    <!-- SIDEBAR -->
    <b-sidebar
      width="40%"
      right
      shadow
      parent="body"
      default-index="1"
      spacer
      v-model="active"
      title="Urgency settings"
      class="sidebar"
    >
      <div class="px-3 py-2">
        <b-form @submit.prevent>
          <b-row>
            <b-col md="12">
              <b-form-group
                label="Name"
                label-for="inputName"
                label-cols-md="12"
              >
                <b-form-input id="inputName" placeholder="Urgency name" v-model="data.selectedUrgencyLevel.name"/>
              </b-form-group>
            </b-col>

            <b-col md="12">
              <b-form-group
                label="Description"
                label-for="textarea-description"
                label-cols-md="12"
              >
                <b-form-textarea
                  v-model="data.selectedUrgencyLevel.description"
                  id="textarea-description"
                  placeholder="Urgency description"
                  rows="3"
                />
              </b-form-group>
            </b-col>

            <b-col md="12">
              <b-form-group
                label="Weeks"
                label-for="inputScore"
                label-cols-md="12"
              >
              <b-form-spinbutton
                id="inputScore"
                v-model="data.selectedUrgencyLevel.score"
                min="0"
                max="100"
              />
              </b-form-group>
            </b-col>

            <b-col md="12">
              <b-form-group
                label="Color"
                label-for="inputColor"
                label-cols-md="12"
              >
              <b-form-input id="inputColor" placeholder="Urgency #colorcode" v-model="data.selectedUrgencyLevel.color"/>
              </b-form-group>
            </b-col>

            <!-- save -->
            <b-col md="12">
              <b-button
              @click="saveUrgencyLevel"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                variant="primary"
                class="mr-1"
              >
                Save
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </div>
    </b-sidebar>
    <!-- /SIDEBAR -->
  </section>
</template>



<script>
import {
  BCard,
  BCardText,
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BFormSpinbutton,
  BFormTextarea,
  BButton,
  BSidebar,
} from "bootstrap-vue";
import vSelect from "vue-select";
import UrgencyLevelsTable from "./UrgencyLevelsTable.vue";

import Ripple from "vue-ripple-directive";
import Cleave from "vue-cleave-component";

export default {
  data() {
    return {
      active: false,
      data: {
        selectedUrgencyLevel:{},
        urgencyLevels: [],
      },
    };
  },
  components: {
    BRow,
    BCol,
    BCard,
    BCardText,
    BForm,
    BFormGroup,
    BFormInput,
    BFormSpinbutton,
    BFormTextarea,
    BButton,
    vSelect,
    Cleave,
    UrgencyLevelsTable,
    BSidebar,
  },
  directives: {
    Ripple,
  },
  methods: {
    async saveUrgencyLevel() {
      var url = '/urgencylevels';
      var method = 'post'
      if (this.data.selectedUrgencyLevel.id) {
          url+='/'+this.data.selectedUrgencyLevel.id
          method = 'put'
      }
        

      const res = await this.callApi(method, url, this.data.selectedUrgencyLevel);

      this.active=false;
      this.loadUrgencyLevels()

    },
    editUrgencyLevel(urgencyLevel) {
      this.data.selectedUrgencyLevel = {...urgencyLevel};
      this.active=true
    },
    async loadUrgencyLevels() {
      const res = await this.callApi('get', '/urgencylevels');
        this.data.urgencyLevels = res.data.result;
    },
    async deleteUrgencyLevel(urgencyLevel) {
      if (!confirm("Are you sure you want to delete this urgencylevel?")) return;
      const res = await this.callApi('delete', '/urgencylevels/'+urgencyLevel.id);
      this.loadUrgencyLevels()
    },
    onContext(ctx) {
      
      // The date formatted in the locale, or the `label-no - date - selected` string
      this.formatted = ctx.selectedFormatted;
      // The following will be an empty string until a valid date is entered
      this.dobSelected = ctx.selectedYMD;
    },
  },
  mounted() {
    this.loadUrgencyLevels();
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

.col-form-label {
  min-height: 2.45rem;
}
</style>